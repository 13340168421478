import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Select, Input, Button, Alert, theme,ConfigProvider, Modal, Space, Typography, Form } from 'antd';
import 'antd/dist/reset.css';
import { ThemeGrid } from './ThemeGrid';

const { Option } = Select;

const { useToken } = theme;

// Define a function to set the theme in local storage
export const setTheme = (custTheme) => {
  console.log(custTheme);
  localStorage.setItem('themeP', custTheme[0].primaryColor);
  localStorage.setItem('themeB', custTheme[0].baseColor);
  localStorage.setItem('themeT', custTheme[0].textColor);
};

// Define a function to get the theme from local storage
export const getTheme = () => {
  // Check if the theme exists in local storage
  if (localStorage.getItem('themeP') === undefined){
    // Set the default theme
    setTheme({primaryColor:'#7FB069',baseColor:'#282c34',textColor:'#cccccc'});
  }
  document.documentElement.style.setProperty('--primaryColor', localStorage.getItem('themeP'));
  document.documentElement.style.setProperty('--baseColor', localStorage.getItem('themeB'));
  document.documentElement.style.setProperty('--textColor', localStorage.getItem('themeT'));
  // Return the theme from local storage
  return({
    primaryColor:localStorage.getItem('themeP'),
    baseColor:localStorage.getItem('themeB'),
    textColor:localStorage.getItem('themeT')
  });
};

const MACHINE_LIST = {
  'Anycubic i3 Mega': 150,
  'Anycubic Kobra': 150,
  'Artillery Sidewinder X1': 180,
  'BQ Witbox 2': 160,
  'Creality CR-10': 160,
  'Creality K1': 170,
  'Creality K1 Max': 175,
  'Dremel Digilab 3D45': 180,
  'Ender 3': 150,
  'Ender 3 Pro': 350,
  'FlashForge Creator Pro': 170,
  'MakerBot Replicator+': 190,
  'Monoprice Maker Select V2': 150,
  'Prusa i3 MK3': 120,
  'Prusa i3 MK3S+': 150,
  'Raise3D Pro2': 220,
  'Snapmaker 2.0': 200,
  'Tiertime UP Mini 2': 140,
  'Voxelab Aquila': 150
};


const FILAMENT_MATERIALS = {
    'PLA': {
        'pricePerKg': 20,
        'density': 1.24, // in g/cm³
        'description': 'Polylactic Acid - Biodegradable thermoplastic made from renewable resources. Easy to print with and commonly used.'
    },
    'ABS': {
        'pricePerKg': 25,
        'density': 1.04,
        'description': 'Acrylonitrile Butadiene Styrene - Durable and heat-resistant. Used for more robust prints but requires a heated bed.'
    },
    'PETG': {
        'pricePerKg': 30,
        'density': 1.27,
        'description': 'Polyethylene Terephthalate Glycol - Combines the ease of PLA with the strength and durability of ABS.'
    },
    'TPU': {
        'pricePerKg': 40,
        'density': 1.21,
        'description': 'Thermoplastic Polyurethane - Flexible and elastic material. Used for parts that need to bend or flex.'
    },
    'Nylon': {
        'pricePerKg': 50,
        'density': 1.15,
        'description': 'Durable and strong material with high temperature resistance. Often used for gears and moving parts.'
    },
    'PVA': {
        'pricePerKg': 60,
        'density': 1.23,
        'description': 'Polyvinyl Alcohol - Water-soluble material, often used as support material.'
    },
    'PC': {
        'pricePerKg': 70,
        'density': 1.2,
        'description': 'Polycarbonate - Extremely durable and heat-resistant. Used for parts that need to withstand high temperatures or impacts.'
    },
    'ASA': {
        'pricePerKg': 30,
        'density': 1.07,
        'description': 'Acrylic Styrene Acrylonitrile - Similar to ABS but with better UV resistance. Used for outdoor applications.'
    },
    'PP': {
      'pricePerKg': 25,
      'density': 0.9,
      'description': 'Polypropylene - Lightweight and fatigue resistant. Commonly used for living hinges.'
    },
    'PEI': {
      'pricePerKg': 90,
      'density': 1.27,
      'description': 'Polyetherimide - High-performance thermoplastic with excellent heat and flame resistance.'
    }
  };
  
  
  const AVERAGE_USAGE_PERCENTAGE = 0.6;
  function App() {
    const [form] = Form.useForm();
    const [custTheme, setcustTheme] = useState(
      getTheme() || {primaryColor:'#7FB069',baseColor:'#282c34',textColor:'#cccccc'}
      );
      const [filamentDescription, setFilamentDescription] = useState('Polylactic Acid - Biodegradable thermoplastic made from renewable resources. Easy to print with and commonly used.');
      const [filamentType, setFilamentType] = useState('PLA');
      const [length, setLength] = useState();
      const [costPerKg, setCostPerKg] = useState(20);
      const [machineCostPerHour, setMachineCostPerHour] = useState();
      const [estimatedPrintHours, setEstimatedPrintHours] = useState();
  const [estimatedPrintMinutes, setEstimatedPrintMinutes] = useState();
  const [marginPercent, setMarginPercent] = useState();
  const [weight, setWeight] = useState(null);
  const [price, setPrice] = useState(null);
  const [finalPrice, setFinalPrice] = useState(null);
  const [machineWattage, setMachineWattage] = useState(150);
  const [kWhPrice, setKWhPrice] = useState();

        const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  
  const handleFilamentChange = (value) => {
    setFilamentType(value);
    setFilamentDescription(FILAMENT_MATERIALS[value].description);
    setCostPerKg(FILAMENT_MATERIALS[value].pricePerKg);
  }
  
  const handleMachineChange = (value) => {
  const selectedWattage = MACHINE_LIST[value];
  setMachineWattage(selectedWattage);
  form.setFieldsValue({ machineWattage: selectedWattage });
};

  const calculateWeightAndPrice = () => {
    const radius = 1.75 / 2 / 10;
  const volume = Math.PI * Math.pow(radius, 2) * length * 100;
    const calculatedWeight = volume * FILAMENT_MATERIALS[filamentType].pricePerKg;
    setWeight(calculatedWeight.toFixed(2));
    
    console.log(FILAMENT_MATERIALS[filamentType].pricePerKg)
    
    const totalTimeInHours = parseFloat(estimatedPrintHours) + (parseFloat(estimatedPrintMinutes) / 60);
    
    // Electricity cost calculation
    const avgWattage = machineWattage * 0.6; // Assuming 80% average wattage utilization (this can be adjusted as per specific requirements)
    const electricityCostForPrint = avgWattage * kWhPrice * totalTimeInHours;
    
    const basePrice = (calculatedWeight / 1000) * costPerKg + electricityCostForPrint + (machineCostPerHour * totalTimeInHours);
    const margin = (marginPercent / 100) * basePrice;
    const calculatedFinalPrice = basePrice + margin;
    
    setPrice(basePrice.toFixed(2));
    setFinalPrice(calculatedFinalPrice.toFixed(2));
  };
  
  
  return (
    <ConfigProvider 
    theme={{
      token: {
        // Set the theme colors using state
        colorPrimary:custTheme?.primaryColor || '#7FB069',
        colorBgBase:custTheme?.baseColor || '#282c34',
        borderRadius:"8px",
        colorTextBase:custTheme?.textColor || '#cccccc',
        colorInfo:"#6CA6C1",
        colorError:"#EF767A",
        colorSuccess:'#7FB069',
        colorWarning:'#EDAE49'
          },
        }}
        >
      <Modal title="Color Settings" open={isModalOpen} width={'70%'} onCancel={handleCancel} footer={[]}>
      <Space  direction="vertical">
      <ThemeGrid/>
        </Space>
      </Modal>
      <div style={{ padding: '50px', backgroundColor: custTheme?.baseColor, height: "100vh", overflow: "auto" }} zIndex={1}>
    <Typography.Title level={1} style={{ textAlign: 'center', color: custTheme?.textColor }}>
        LayerLoom - 3D Print Cost Calculator
    </Typography.Title>
    <Form>
      
        
    <Select defaultValue="PLA" onChange={handleFilamentChange} style={{width:"100%"}}>
        {Object.keys(FILAMENT_MATERIALS).map(type => (
          <Option key={type} value={type}>{type}</Option>
          ))}
    </Select>
    <p style={{ color: custTheme?.textColor, marginTop: '10px' }}>
    {filamentDescription}
</p>

    <Input 
        type="number"
        placeholder="Enter length in meters"
        value={length}
        onChange={e => setLength(e.target.value)}
        style={{ margin: '10px 0' }}
        prefix="Length:"
        suffix="m"
    />
    <Input 
        type="number"
        id='costPerKgInput'
        placeholder="Enter cost per kg"
        value={costPerKg}
        onChange={e => setCostPerKg(e.target.value)}
        style={{ margin: '0' }}
        prefix="Cost:"
        suffix="$/kg"
    />
    <Input 
        type="number"
        placeholder="Enter machine cost per hour"
        value={machineCostPerHour}
        onChange={e => setMachineCostPerHour(e.target.value)}
        style={{ margin: '10px 0' }}
        prefix="Cost:"
        suffix="$/hr"
    />
  <Select placeholder="Select a machine" defaultValue="Ender 3" onChange={handleMachineChange} style={{width:"100%"}}>
    {Object.keys(MACHINE_LIST).map(machine => (
      <Option key={machine} value={machine}>{machine}</Option>
    ))}
  </Select>
    <Input 
        type="number"
        placeholder="Enter machine wattage in W"
        value={machineWattage}
        onChange={e => setMachineWattage(e.target.value)}
        style={{ margin: '10px 0 0 0' }}
        prefix="Wattage:"
        suffix="W"
    />
    <Input 
        type="number"
        placeholder="Enter kWh price"
        value={kWhPrice}
        onChange={e => setKWhPrice(e.target.value)}
        style={{ margin: '10px 0' }}
        prefix="Price:"
        suffix="$/kWh"
    />
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Input 
            type="number"
            placeholder="Hours"
            value={estimatedPrintHours}
            onChange={e => setEstimatedPrintHours(e.target.value)}
            style={{ width: '48%', marginRight: '4%' }}
            prefix="Time:"
            suffix="hrs"
        />
        <Input 
            type="number"
            placeholder="Minutes"
            value={estimatedPrintMinutes}
            onChange={e => setEstimatedPrintMinutes(e.target.value)}
            style={{ width: '48%' }}
            prefix="Time:"
            suffix="mins"
        />
    </div>

        <Input 
          type="number"
          placeholder="Enter margin percentage"
          value={marginPercent}
          onChange={e => setMarginPercent(e.target.value)}
          style={{ margin: '10px 0' }}
          suffix="%"
        />
        <Button type="primary" onClick={calculateWeightAndPrice}>Calculate</Button>
        <Button type="secondary" onClick={showModal}>Themes</Button>
        {weight && (
          <Alert 
            message={`The weight is approximately ${weight} grams.`}
            type="info"
            style={{ margin: '10px 0', color:'black' }}
          />
        )}

        {price && (
          <Alert 
            message={`The base price (including electricity and machine time) is $${price}.`}
            type="warning"
            style={{ margin: '10px 0', color:'black' }}
          />
        )}
        {finalPrice && (
          <Alert 
            message={`The final price (after margin) is $${finalPrice}.`}
            type="success"
            style={{ margin: '10px 0', color:'black' }}
          />
        )}
      </Form>
      </div>
    </ConfigProvider>
  );
}

export default App;

ReactDOM.render(<App />, document.getElementById('root'));
